
import { defineComponent, ref, watch } from 'vue';
import RichText from '@projectfive/v-richtext';
import { useRoute } from 'vue-router';
import http from '@/services/http';
import '@projectfive/v-richtext/dist/style.css';

export default defineComponent({
    name: 'Article',
    components: {
        RichText,
    },
    setup() {
        const blocks = ref({});
        const route = useRoute();

        watch(() => route.path, getData, { immediate: true });

        function getData() {
            const articleMeta: any = route.meta.article;
            if (articleMeta && articleMeta.endpoint) {
                if ((window as any).PRERENDER_INJECTED) {
                    const routedata = (window as any).PRERENDER_INJECTED.routeData[route.path];
                    if (routedata) {
                        processData(routedata);
                    }
                } else {
                    http.get(articleMeta.endpoint)
                        .then((r) => {
                            processData(r.results);
                        }).catch((err) => {
                            console.debug(err);
                        });
                }
            }
        }

        function processData(r) {
            const item = r;
            if (item.config && Array.isArray(item.config.content)) {
                blocks.value = item.config;
            }
        }

        return {
            blocks,
            blockSettings: {
                image: {
                    attrs: { class: 'w-m' },
                },
                embed: {
                    attrs: { class: 'w-m' },
                },
                attrs: {
                    class: 'w-s mx-auto',
                },
            },
        };
    },
});
